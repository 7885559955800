@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');

body, html {
  font-family: 'ABC Simon Mono', monospace !important;
}

.section-title {
  font-family: 'Merriweather', serif !important;
  font-weight: 700;
}

