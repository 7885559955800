.body-section {
    padding-bottom: 40px;

     /* Adjust as needed for equal spacing above and below the section */
  }
  
  .section-header {
    margin-bottom: 60px; /* Space between the section title and the content */
  }
  
  .section-title {
    font-family: 'Merriweather', serif;
    font-size: 2rem; /* Adjust as needed for better visibility */
    margin-top: 80px;
    
  }
  
  hr {
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
  }
  