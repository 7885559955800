.timeline {
    border-left: 1px solid hsl(0, 0%, 90%);
    position: relative;
    list-style: none;
    padding-left: 20px;
  }
  
  .timeline .timeline-item {
    position: relative;
    padding-left: 40px;
  }
  
  .timeline .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
    left: -26px;
    background-color: hsl(0, 0%, 90%);
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
  }
  
  .fw-bold {
    font-weight: bold;
  }
  
  .worktitle {

    font-size: 1rem;
    font-weight: 500;
    margin-top: 5px;
  }
  